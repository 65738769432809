<template>
  <!-- 规则配置 -->
  <div class="ruleConfig">
    <div class="rule-tabs">
      <div class="rule-tabs-title">
        <div
          v-for="(item, index) in tabsList"
          :key="item.text"
          :class="item.class"
          @click="tabClick(index)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="rule-tabs-content">
        <el-button
          v-if="operAtionIndex == 1"
          class="tabs-add"
          size="small"
          sort="primary"
          @click="addFun"
          >添加</el-button
        >
        <y-table :columns="titleName" :data="tableData" :col-class-type="true">
          <span slot="action" slot-scope="{ row, $index }">
            <el-button
              v-if="
                operAtionIndex == 1
                  ? tableData.length == $index + 1
                    ? true
                    : false
                  : true
              "
              type="text"
              size="small"
              sort="primary"
              @click="editFun(row)"
              >编辑</el-button
            >
            <el-button
              v-if="
                operAtionIndex == 1
                  ? tableData.length == $index + 1
                    ? true
                    : false
                  : false
              "
              type="text"
              size="small"
              sort="primary"
              @click="deleteFun(row)"
              >删除</el-button
            >
          </span>
        </y-table>
        <!-- <Table
          :table-data="tableData"
          :title-name="titleName"
          :operation-width="operAtionIndex == 1 ? '110' : '70'"
          :operation="true"
          :col-class-type="true"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                operAtionIndex == 1
                  ? scope.scopeRow.DataLength == scope.scopeRow.$index + 1
                    ? true
                    : false
                  : true
              "
              type="text"
              size="small"
              sort="primary"
              @click="editFun(scope.scopeRow)"
              >编辑</el-button
            >
            <el-button
              v-if="
                operAtionIndex == 1
                  ? scope.scopeRow.DataLength == scope.scopeRow.$index + 1
                    ? true
                    : false
                  : false
              "
              type="text"
              size="small"
              sort="primary"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </Table>-->
      </div>
    </div>
    <!-- 弹窗 -->
    <OperAtion
      v-if="operAtionShow"
      :header-text="headerText"
      :popup-num="popupNum"
      :oper-ation-data="operAtionData"
      @confirmFun="confirmFun"
      @closePopupFun="closePopupFun"
    />
    <!-- 新版弹窗 -->
    <charterPopup
      v-if="charterOnoff"
      header-text="编辑"
      @confirmFun="NewconfirmFun"
      @closePopupFun="NewclosePopupFun"
    >
      <div class="charterBox">
        <span>{{ charterKey.regulationName }}</span>
        <el-input
          v-if="
            charterShow &&
            (charterKey.id === 64 ||
              charterKey.id === 65 ||
              charterKey.id === 48)
          "
          v-model="ticketValue"
          onkeyup="this.value = this.value.replace( /[^\d.]/g,'');"
        ></el-input>
        <el-input
          v-if="
            charterShow &&
            !(
              charterKey.id === 64 ||
              charterKey.id === 65 ||
              charterKey.id === 48
            )
          "
          v-model="ticketValue"
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
        ></el-input>
        <el-select
          v-if="
            !charterShow && charterKey.regulationType != 'SEE_PASSENGER_ORDERS'
          "
          v-model="ticketSelectValue"
          placeholder="请选择"
        >
          <el-option
            v-for="item in downMenu"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <el-input-number
          v-if="
            !charterShow && charterKey.regulationType == 'SEE_PASSENGER_ORDERS'
          "
          v-model="ticketSelectValue"
          :step="1"
          :min="0"
          :max="100"
          size="medium"
        ></el-input-number>
      </div>
    </charterPopup>
  </div>
</template>
<script>
import {
  ticketRegulationAPI,
  refundTicketRegulationAPI,
  scheduleRegulationAPI,
  ticketRegulationUpdateAPI,
  scheduleRegulationUpdateAPI,
  refundTicketRegulationDeleteAPI,
  refundTicketRegulationAddAPI,
  refundTicketRegulationUpdateAPI,
  rebookRegulationApi,
  rebookRegulationUpdateApi,
  queryElectriCallRegulation,
  // updateElectriCallRegulation,
  getAllRegulationByChannelNo,
  updateChannelNoRegulation,
  getAllDriverRegulationByChannelNo,
  updateDriverChannelNoRegulation,
} from "@/api/lib/api.js";
import {
  getAllRegulationByChannelNoAPI,
  updateChannelNoRegulationAPI,
} from "@/api/lib/remouldAPI.js";
export default {
  props: {
    channelNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ticketObj: null,
      ticketValue: "",
      ticketSelectValue: "",
      charterShow: true,
      charterOnoff: false,
      charterKey: {},
      // ----------------------
      headerText: "",
      popupNum: null,
      operAtionIndex: 0,
      // tabsList: [
      //   { class: "tabs tabs-active", text: "购票规则" },
      //   { class: "tabs", text: "退票规则" },
      //   { class: "tabs", text: "调度规则" },
      //   { class: "tabs", text: "改签规则" },
      //   { class: "tabs", text: "电召订票规则" },
      //   { class: "tabs", text: "乘车码" },
      //   { class: "tabs", text: "司机端规则" },
      // ],
      tabsList: [
        { class: "tabs tabs-active", text: "购票规则" },
        { class: "tabs", text: "退票规则" },
        { class: "tabs", text: "改签规则" },
        { class: "tabs", text: "检票规则" },
        { class: "tabs", text: "司机端规则" },
      ],
      operAtionShow: false,
      tableData: [],
      titleName: [
        {
          label: "序号",
          type: "index",
          width: 50,
        },
        {
          label: "规则名",
          prop: "regulationName",
        },
        {
          label: "值",
          prop: "regulationValue",
        },
        {
          label: "单位",
          prop: "unitType",
          customRender: (res) => {
            return res == 0
              ? "天"
              : res == 1
              ? "分钟"
              : res == 2
              ? "人"
              : res == 3
              ? "小时"
              : res == 4
              ? "个"
              : res == 6
              ? "平方米"
              : res == 7
              ? "是/否"
              : res == 8
              ? "百分比"
              : "";
          },
        },
        {
          label: "操作",
          scopedSlots: "action",
          width: "70",
        },
      ],
      operAtionData: {},
      downMenu: [],
    };
  },
  watch: {
    channelNo() {
      this.renderDataA();
    },
  },
  mounted() {
    this.renderDataA();
  },
  methods: {
    // 新版弹窗确定按钮返回事件
    NewconfirmFun() {
      let ob = this.deepClone(this.operAtionData);
      if (ob.unitType == 5 || ob.unitType == 4) {
        ob.regulationValue = this.ticketSelectValue;
      } else {
        ob.regulationValue = this.ticketValue;
      }
      // if (this.operAtionIndex == 3) {
      if (this.operAtionIndex == 2) {
        rebookRegulationUpdateApi(ob).then((res) => {
          if (res.code == "SUCCESS") {
            this.charterOnoff = false;
            this.renderDataD();
          }
        });
      }
      if (this.operAtionIndex == 3) {
        updateChannelNoRegulationAPI(ob).then((res) => {
          if (res.code == "SUCCESS") {
            this.charterOnoff = false;
            this.renderDataE();
          }
        });
      }
      if (this.operAtionIndex == 5) {
        ob.regulationValue = this.ticketSelectValue;
        updateChannelNoRegulation(ob).then((res) => {
          if (res.code == "SUCCESS") {
            this.charterOnoff = false;
            this.renderDataF();
          }
        });
      }
      if (this.operAtionIndex == 4) {
        ob.regulationValue = this.ticketSelectValue;
        const reg = /^(0|\+?[1-9][0-9]*)$/; //0和正整数
        if (
          ob.regulationType == "SEE_PASSENGER_ORDERS" &&
          !reg.test(ob.regulationValue)
        ) {
          this.$message.warning("请输入非负整数！");
          return;
        }
        updateDriverChannelNoRegulation(ob).then((res) => {
          if (res.code == "SUCCESS") {
            this.charterOnoff = false;
            this.renderDataG();
          }
        });
      }
    },
    // 新版弹窗取消按钮返回事件
    NewclosePopupFun() {
      this.charterOnoff = false;
    },
    // 添加按钮点击
    addFun() {
      if (this.tableData.length == 0) {
        this.operAtionData = {
          departureTimeStart: 0,
        };
      } else {
        this.operAtionData = {
          departureTimeStart:
            this.tableData[this.tableData.length - 1].departureTimeEnd,
        };
      }
      this.headerText = "新增";
      this.popupNum = 6;
      this.operAtionShow = true;
    },
    // 删除按钮点击
    deleteFun(val) {
      refundTicketRegulationDeleteAPI({ id: val.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderDataB();
        }
      });
    },
    // 弹窗确定回调
    confirmFun(val) {
      switch (this.operAtionIndex) {
        case 0:
          ticketRegulationUpdateAPI({
            id: val.id,
            regulationValue: val.regulationValue,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.operAtionShow = false;
              this.renderDataA();
            }
          });
          break;
        case 1:
          if (
            val.departureTimeStart === "" ||
            val.departureTimeStart == undefined ||
            val.departureTimeEnd === "" ||
            val.departureTimeEnd == undefined ||
            val.chargeRate === "" ||
            val.chargeRate == undefined
          ) {
            this.$message.error("请填写必填项");
          } else {
            if (this.headerText == "新增") {
              refundTicketRegulationAddAPI({
                regulationName: val.regulationName,
                departureTimeStart: val.departureTimeStart,
                departureTimeEnd: val.departureTimeEnd,
                chargeRate: val.chargeRate,
                channelNo: this.channelNo,
              }).then((res) => {
                if (res.code == "SUCCESS") {
                  this.operAtionShow = false;
                  this.renderDataB();
                }
              });
            } else {
              refundTicketRegulationUpdateAPI({
                id: val.id,
                regulationName: val.regulationName,
                departureTimeStart: val.departureTimeStart,
                departureTimeEnd: val.departureTimeEnd,
                chargeRate: val.chargeRate,
              }).then((res) => {
                if (res.code == "SUCCESS") {
                  this.operAtionShow = false;
                  this.renderDataB();
                }
              });
            }
          }
          break;
        // case 2:
        //   scheduleRegulationUpdateAPI({
        //     id: val.id,
        //     departureTimeStart: val.departureTimeStart,
        //     departureTimeEnd: val.departureTimeEnd,
        //   }).then((res) => {
        //     if (res.code == "SUCCESS") {
        //       this.operAtionShow = false;
        //       this.renderDataC();
        //     }
        //   });
        //   break;
      }
    },
    // 弹窗取消回调
    closePopupFun() {
      this.operAtionShow = false;
    },
    // 点击表格编辑按钮
    editFun(val, scope) {
      this.operAtionData = this.deepClone(val);
      this.headerText = "编辑";
      switch (this.operAtionIndex) {
        case 0:
          this.popupNum = 3;
          this.operAtionShow = true;
          break;
        case 1:
          this.popupNum = 6;
          this.operAtionShow = true;
          break;
        // case 2:
        //   this.popupNum = 5;
        //   this.operAtionShow = true;
        //   break;
        // 改签规则
        case 2:
          this.charterOnoff = true;
          this.charterKey = val;
          if (val.unitType == 5) {
            this.charterShow = false;
            this.ticketSelectValue = val.regulationValue;
            this.downMenu = ["是", "否"];
          } else if (val.unitType == 4) {
            this.charterShow = false;
            this.ticketSelectValue = val.regulationValue;
            this.downMenu = ["按固定值", "按百分比"];
          } else {
            this.charterShow = true;
            this.ticketValue = val.regulationValue;
          }
          break
        case 3:
          // this.charterOnoff = true;
          // this.charterKey = val;
          // if (val.unitType == 5) {
          //   this.charterShow = false;
          //   this.ticketSelectValue = val.regulationValue;
          //   this.downMenu = ["是", "否"];
          // } else if (val.unitType == 4) {
          //   this.charterShow = false;
          //   this.ticketSelectValue = val.regulationValue;
          //   this.downMenu = ["按固定值", "按百分比"];
          // } else {
          //   this.charterShow = true;
          //   this.ticketSelectValue = val.regulationValue;
          // }
          this.charterOnoff = true;
          this.charterKey = val;
          this.charterShow = false;
          this.downMenu = ["是", "否"];
          this.ticketSelectValue = val.regulationValue;
          break;
        // case 5:
        //   this.charterOnoff = true;
        //   this.charterKey = val;
        //   this.charterShow = false;
        //   this.downMenu = ["是", "否"];
        //   this.ticketSelectValue = val.regulationValue;
        //   break;
        case 4:
          this.charterOnoff = true;
          this.charterKey = val;
          this.charterShow = false;
          this.downMenu = ["是", "否"];
          this.ticketSelectValue = val.regulationValue;
          break;
      }
    },
    // 点击tab标签
    tabClick(index, flag) {
      this.operAtionIndex = index;
      this.tabsList.map((item) => {
        item.class = "tabs";
      });
      this.tabsList[index].class = "tabs tabs-active";
      switch (index) {
        case 0:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "值",
              prop: "regulationValue",
            },
            {
              label: "单位",
              prop: "unitType",
              customRender: (res) => {
                return res == 0
                  ? "天"
                  : res == 1
                  ? "分钟"
                  : res == 2
                  ? "元/人"
                  : res == 4
                  ? "人"
                  : res == 6
                  ? "平方米"
                  : res == 8
                  ? "百分比"
                  : "";
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: 70,
            },
          ];
          !flag && this.renderDataA();
          break;
        case 1:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: "50",
            },
            {
              label: "发车前时间区间起（含）",
              prop: "departureTimeStart",
            },
            {
              label: "发车前时间区间止（不含）",
              prop: "departureTimeEnd",
            },
            {
              label: "收取手续费比率",
              prop: "chargeRate",
              customRender: (res) => {
                return res + "%";
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: 110,
            },
          ];
          this.renderDataB();
          break;
        // case 2:
        //   this.titleName = [
        //     {
        //       label: "序号",
        //       type: "index",
        //       width: 50,
        //     },
        //     {
        //       label: "规则名",
        //       prop: "regulationName",
        //     },
        //     {
        //       label: "发车前（分钟）",
        //       prop: "departureTimeStart",
        //     },
        //     {
        //       label: "操作",
        //       scopedSlots: "action",
        //       width: "70",
        //       showOverflowTooltip: false,
        //     },
        //   ];
        //   this.renderDataC();
        //   break;
        case 2:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "值",
              prop: "regulationValue",
            },
            {
              label: "单位",
              prop: "unitType",
              customRender: (res) => {
                return res == 1
                  ? "次"
                  : res == 2
                  ? "分钟"
                  : res == 3
                  ? "元"
                  : res == 4
                  ? "字符值"
                  : res == 5
                  ? "是/否"
                  : res == 6
                  ? "百分比"
                  : "";
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: "70",
            },
          ];
          this.renderDataD();
          break;
        case 3:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "值",
              prop: "regulationValue",
            },
            {
              label: "单位",
              prop: "unitType",
              customRender: (res) => {
                let unitTypeList = ["-", "次", "分钟", "元", "字符值", "是/否", "百分比"];
                return unitTypeList[res];
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: "70",
            },
          ];
          this.renderDataE();
          break;
        // case 5:
        //   this.titleName = [
        //     {
        //       label: "序号",
        //       type: "index",
        //       width: 50,
        //     },
        //     {
        //       label: "规则名",
        //       prop: "regulationName",
        //     },
        //     {
        //       label: "值",
        //       prop: "regulationValue",
        //     },
        //     {
        //       label: "单位",
        //       prop: "unitType",
        //       customRender: (res) => {
        //         return "是/否";
        //       },
        //     },
        //     {
        //       label: "操作",
        //       scopedSlots: "action",
        //       width: "70",
        //     },
        //   ];
        //   this.renderDataF();
        //   break;
        case 4:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "值",
              prop: "regulationValue",
            },
            {
              label: "单位",
              prop: "regulationType",
              customRender: (res) => {
                return res == "SEE_PASSENGER_ORDERS" ? "天" : "是/否";
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: "70",
            },
          ];
          this.renderDataG();
          break;
      }
    },
    // 购票规则数据获取
    renderDataA() {
      ticketRegulationAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 退票规则数据获取
    renderDataB() {
      refundTicketRegulationAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // // 调度规则数据获取
    // renderDataC() {
    //   scheduleRegulationAPI({ channelNo: this.channelNo }).then((res) => {
    //     if (res.code == "SUCCESS") {
    //       this.tableData = res.data;
    //     }
    //   });
    // },
    // 改签规则数据获取
    renderDataD() {
      rebookRegulationApi({ business: 1, channelNo: this.channelNo }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data;
          }
        }
      );
    },
    // 检票规则
    renderDataE() {
      getAllRegulationByChannelNoAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    //乘车码规则数据获取
    renderDataF() {
      getAllRegulationByChannelNo({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    //司机端规则
    renderDataG() {
      getAllDriverRegulationByChannelNo({ channelNo: this.channelNo }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data;
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.ruleConfig {
  height: 100%;
  .rule-tabs {
    width: 100%;
    border: 1px solid #dcdfe6;
    .rule-tabs-title {
      height: 40px;
      background: #f5f7fa;
      border-bottom: 1px solid #dcdfe6;
      .tabs {
        display: inline-block;
        height: 100%;
        padding: 0 20px;
        cursor: pointer;
        color: #909399;
        font-size: 14px;
        line-height: 40px;
        margin-left: -1px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
      .tabs-active {
        border-bottom: 1px solid #ffffff;
        color: #409eff;
        background-color: #fff;
        border-right: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
      }
    }
    .rule-tabs-content {
      padding: 15px;
      background: #ffffff;
      .tabs-add {
        background: #336fee;
        color: #ffffff;
        margin-bottom: 10px;
      }
    }
  }
}
.charterBox {
  margin: 30px 0;
  span {
    display: inline-block;
    margin-left: 40px;
    margin-right: 20px;
  }
  .el-input {
    width: 240px;
    display: inline-block;
  }
}
</style>
